@import "../variables.module";

$cardColor: #2d3644;
// .game-container {
//     padding-top: 20px;
// }

$BattleRed: #ff4c4c;
$BattleGreen: #85f074;
$BattleBlue: #0077db;
$BattleYellow: #ffd54f;

$multiplierColor: #ff8c19;
$terminalColor: #ffe500;
$loserWinsColor: #f86767;

.battles-home-loading {
  // border: 1px solid #fff;
  height: 200px;
  position: relative;
}

.beta {
  background-color: #0077db;
  font-size: 12px;
  padding: 5px;
  border-radius: $radius;
}

.case-item-list-modal-container {
  width: 500px;

  .case-item-list {
    margin-top: 20px;
    // border: 1px solid #fff;
    // height: 500px;

    display: flex;
    flex-direction: column;
    // grid-template-rows: repeat(2, auto); // Creates two rows
    // grid-template-columns: repeat(
    //   auto-fill,
    //   minmax(150px, 1fr)
    // ); // Creates columns that automatically fill the container, each column at least 150px wide
    gap: 10px; // Sp
  }
}

.main-spinner-gameplay-container {
  position: relative;
  overflow: hidden;
  // border: 1px solid #fff;

  &.hide-multi-spinner {
    .multi-spinner-container {
      opacity: 0;
    }
  }
  &.show-multi-spinner {
    .multi-spinner-container {
      opacity: 1;
    }
  }
  .multi-spinner-display {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(3px);
    transition: opacity 0.3s ease-in-out;
  }
  .multi-spinner-container {
    padding-top: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    transition: opacity 0.3s ease-in-out;

    // opacity: 1;

    .multi-spinner-name {
      justify-content: center;
      display: flex;
      align-items: center;
      gap: 5px;
      position: absolute;
      z-index: 20;
      border-radius: $radius;
      top: 10px;
      left: 10px;
      right: 10px;

      height: 47px;
      background: radial-gradient(circle at 50% 150%, #41466c, #1b1e38);
    }
  }
}

.double-down-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  transition: opacity 0.3s ease;

  .double-down-btn {
    width: 150px;
    height: 50px;
    border-radius: $radius;
    // background-color: #aa9655;
    color: #fff;
    border: 2px solid #85f074;

    font-size: 12px;
    transition: box-shadow 0.3s ease;
    position: relative;
    display: block;
    padding-bottom: 4px;
    box-shadow: 0px 0px 3px 0px #71ce61;
    background: radial-gradient(
      circle at center,

      #71ce61,
      #4e9342
    );
    gap: 15px;

    .double-down-countdown {
      position: absolute;
      bottom: 7px;
      left: 10px;
      right: 10px;
      height: 2px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.2);

      .inner-bar {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        border-radius: 4px;
      }
    }
    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 7px 0px #71ce61;
    }
  }

  .double-down-desc {
    margin-top: 10px;
    text-align: center;
    font-size: 10px;
    width: 100%;
  }
}

.battle-winnings {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  backdrop-filter: blur(10px);
  // border-radius: 6px;
  // background: linear-gradient(to top, )
  // background-color: #fff;
  transition: opacity 0.3s ease;
  opacity: 0;

  &.open-animation {
    opacity: 1;

    // .center-display {
    //   transition:
    //     opacity 0.3s ease,
    //     transform 0.3s ease;
    //   opacity: 1;
    //   transform: translateY(0);
    // }

    .win-background {
      opacity: 0.4;
      transform: translateY(0);
    }
  }

  &.double-down-state {
    .win-screen-display {
      opacity: 0;
      transform: translateY(100px);
      scale: 0.8;
      transition:
        opacity 0.3s ease,
        transform 0.3s ease,
        scale 0.3s ease;
    }
    .double-down-screen-display {
      opacity: 1;
      transform: translateY(0);
      transition:
        opacity 0.3s ease,
        transform 0.3s ease;
    }
  }

  &.double-down-complete {
    .double-down-screen-display {
      .double-down-amount {
        opacity: 1;
        transition: opacity 0.3s ease;

        &.lose {
          opacity: 0.3;
        }
      }
    }
    &.lose-double-down {
      // border: 1px solid #fff;

      .win-background {
        opacity: 0;
        transform: translateY(0);
      }
      .lose-background {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &.win-double-down {
      .win-background {
        opacity: 0.7;
        transform: translateY(0);
      }
    }
  }

  .win-screen-display {
    padding-left: 20px;
    padding-right: 20px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    p {
      text-align: center;
      font-size: 12px;
      color: #d0d0d0;
    }

    .winning-amount-display-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
    }
  }

  .double-down-screen-display {
    // border: 1px solid #fff;
    opacity: 0;
    width: 100%;
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    pointer-events: none;

    .double-down-amount {
      display: flex;
      gap: 5px;
      align-items: center;
      opacity: 0;

      span {
        font-size: 18px;
      }
    }
  }

  .win-lose-icon {
    display: flex;
    gap: 5px;

    span {
      font-size: 18px;
    }
  }
  h3 {
    font-size: 24px;
    // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;

    background: linear-gradient(180deg, #94ca6b 0%, #fff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: $breakPointTwo) {
      font-size: 16px;
    }
  }
  // .center-display {
  //   z-index: 1;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   gap: 20px;
  //   opacity: 0;
  //   transform: translateY(100px);

  //   @media(max-width: $breakPointOne) {
  //     h3 {
  //       font-size: 24px;
  //     }
  //   }
  // }

  .background-winnings {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    // border: 1px solid #fff;
    svg {
      width: 100%;
      // border: 1px solid pink;
      height: 100%;
      animation: rotate 120s infinite linear;
    }
  }

  .doubled-down {
    font-size: 12px;
  }

  .win-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0;
    transition:
      opacity 0.6s ease,
      transform 0.6s ease;
    transform: translateY(150px);
    background: linear-gradient(to top, #60ac56, #0015262d);
  }

  .lose-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0;
    transition:
      opacity 0.6s ease,
      transform 0.6s ease;
    transform: translateY(150px);
    background: linear-gradient(to top, #586195, #0015262d);
  }
  // mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);

  .right-side-winning-fade {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100px;
    z-index: 5;
    background: linear-gradient(to left, #488140, #84f07400);
    opacity: 1;
  }
  .left-side-winning-fade {
    background: linear-gradient(to right, #488140, #84f07400);
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100px;
    bottom: 0;
    opacity: 1;
  }
}

.maintenance-display {
  span {
    font-size: 14px;
    color: $BattleRed;
  }
}

.case-select-container {
  width: 100%;
  padding-top: 30px;
  width: 800px;
  height: 800px;
}

.end-spin-effect-container {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  // border: 1px solid #fff;

  .animated-div {
    position: absolute;
    width: 10px; // Example size
    height: 10px; // Example size
    // background-color: #f00; // Example color
    // display: flex;
    border-radius: 50%;

    opacity: 1;
    bottom: -50px;
    left: 50%;
    // border: 1px solid #fff;
    // border: 1px solid #fff;
    // border-radius: 50%;
    // Additional styles...
  }
}

.eos-stream-container {
  position: absolute;
  z-index: 12;
  border-radius: $radius;
  // border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(3px);
  top: 220px;
  bottom: 278px;
  // height: 500px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: none;
  opacity: 0;

  // display: none;
  transition: opacity 0.1s ease-in-out;

  &.active-eos {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;

    .eos-stream {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  &.mobile-one {
    top: 425px;
  }

  &.mobile-two {
    top: 515px;
  }

  &.error-overlay-display {
    opacity: 1;
    height: 100%;
    top: 0;
  }

  .error-display {
    text-align: center;
    opacity: 1;
  }

  .eos-stream {
    width: 400px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $primaryBackgroundColor;
    border-radius: $radius;
    flex-direction: column;
    gap: 10px;
    transition:
      transform 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
    transform: translateY(20px);
    opacity: 0;

    @media (max-width: $breakPointTwo) {
      width: 90%;
    }

    span {
      font-size: 13px;

      @media (max-width: $breakPointThree) {
        font-size: 10px;
      }
    }

    .lds-ellipsis,
    .lds-ellipsis div {
      box-sizing: border-box;
    }
    .lds-ellipsis {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 40px;
    }
    .lds-ellipsis div {
      position: absolute;
      top: 23.33333px;
      width: 13.33333px;
      height: 13.33333px;
      border-radius: 50%;
      background: #fff;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
  }
}

.sound-setting {
  border: 2px solid $fontColorTwo;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #191f3b;
  svg {
    width: 15px;
    height: auto;
    path {
      fill: #fff;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.case-heading {
  width: 100%;
  position: relative;
  padding-bottom: $padding;
  // height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  //

  .case-heading-title {
    align-items: center;
    display: flex;
    gap: 20px;

    .icon-title {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    h1 {
      font-size: 18px;
      color: $fontColor;
    }
  }
  .header-middle {
    gap: 10px;
    display: flex;
    // opacity: 0;
    pointer-events: none;
    transition: opacity 300ms ease-in-out;

    &.show-recreate {
      pointer-events: all;
      .recreate-buttons {
        opacity: 1;
      }
    }
    .recreate-buttons {
      display: flex;
      gap: 10px;
      // opacity: 0;
    }
    .auto-start-timer {
      // border: 1px solid #fff;s
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 12px;
      color: $fontColorTwo;
      min-width: 200px;
      height: 60px;
      display: none;
      span {
        display: flex;
        align-items: center;
        gap: 5px;

        svg {
          line-height: 0;
          width: 13px;
        }

        .clock {
          // border: 1px solid #fff;
          min-width: 55px;
        }
      }
    }
    .recreate-cost {
      display: flex;
      gap: 10px;
      align-items: center;

      & > div {
        display: flex;
        gap: 3px;
      }
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .key-count {
    // height: 50px;
    border-radius: 6px;
    width: auto;
    // padding: 0px 15px 0px 11px;
    display: flex;
    // background-color: $rewardBackground;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    font-size: 15px;

    span {
      display: flex !important;
      align-items: center !important;
      gap: 10px !important;
      margin: -4px 0px !important;
      img {
        width: 52px !important;
        height: 52px !important;
        margin-right: 3px !important;
      }
    }
    // span:nth-child(2){
    //   margin-bottom: 5px;
    // }
    // span:last-of-type{
    //   margin-bottom: 5px;
    // }
    .key-amount {
      padding-right: 10px;
      color: $gold;
    }
  }

  &.mobile-two {
    // border: 1px solid #fff;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.heading-nav {
  width: 200px;
}

.vertical-divider {
  width: 1px; /* Set the width of the divider */
  height: 100%; /* Adjust the height as needed */
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  /* Adjust the color and opacity in the gradient to your liking */
}

.battles-details-header {
  height: 130px;
  width: 100%;
  border-radius: $radius;
  background-color: $secondaryBackgroundColor;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;

  // opacity: 0;
  // transform: translateY(20px);

  // background: linear-gradient(
  //   to right,

  //   rgba(255, 255, 255, 0.2),
  //   $secondaryBackgroundColor 20%
  // );

  // &.regular {
  //   background: linear-gradient(
  //     to right,

  //     rgba(255, 76, 76, 0.1),
  //     $secondaryBackgroundColor 20%
  //   );
  // }
  // &.group {
  //   background: linear-gradient(
  //     to right,

  //     rgba(132, 240, 116, 0.2),
  //     $secondaryBackgroundColor 20%
  //   );
  // }
  // &.team {
  //   background: linear-gradient(
  //     to right,

  //     rgba(0, 120, 219, 0.2),
  //     $secondaryBackgroundColor 20%
  //   );
  // }

  .left {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 35px;
    padding-left: 20px;

    .game-mode-title-container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .default-gameplay {
        font-size: 13px;
        color: $fontColorTwo;
      }
    }

    .game-mode-title {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .game-icon {
      svg {
        width: 25px;
        height: auto;
      }
    }

    h1 {
      font-size: 16px;
      text-transform: capitalize;

      span {
        padding-left: 5px;
        letter-spacing: 0.01em;
      }
    }
  }
  .buy-in {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    .funding-amount-header {
      display: flex;
      align-items: center;
      gap: 5px;
      svg {
        width: 18px;
      }
    }

    h3 {
      font-weight: bold;
    }

    .normal-buy-in {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      color: $fontColorTwo;
    }
  }

  .right {
    .game-status-container {
      width: 200px;
      margin-right: 20px;
      position: relative;

      .start-game-overlay-countdown {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: $fontColor;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: $radius;
        z-index: 5;

        transition: opacity 0.3s ease-in-out;

        &.hide {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  .modes-container {
    display: flex;
    gap: 5px;

    .mode {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      border-radius: $radius;
      width: 35px;
      height: 35px;
      border: 1px solid;

      &.multiplier {
        border-color: $multiplierColor;

        background: radial-gradient(
          circle at 50% 150%,
          $multiplierColor,
          rgba(255, 76, 76, 0) 100%
        );
        span {
          color: $multiplierColor;
        }
      }
      &.terminal {
        border-color: $terminalColor;
        background: radial-gradient(
          circle at 50% 150%,
          $terminalColor,
          rgba(255, 76, 76, 0) 100%
        );
        span {
          color: $terminalColor;
        }
      }
      &.loser-wins {
        border-color: $loserWinsColor;
        background: radial-gradient(
          circle at 50% 150%,
          $loserWinsColor,
          rgba(255, 76, 76, 0) 100%
        );
        span {
          color: $loserWinsColor;
        }
      }

      svg {
        width: 15px;
        height: auto;
      }
    }
  }

  &.mobile-one,
  &.mobile-two {
    // flex-direction: column;
    height: auto;
    gap: 10px;
    flex-wrap: wrap;
    // align-items: flex-start;

    > * {
      //  border: 1px solid #fff;
      flex: 1 1 25%;
      // flex-basis: 100%;
    }

    > *:nth-child(4) {
      // border: 1px solid #fff;
      flex: 1 1 25%;
    }
    .left {
      // flex-direction: start;
      justify-content: flex-start;
      // border: 1px solid pink;
      padding-left: 0px;
    }
    .vertical-divider {
      display: none;
    }

    .battle-cases-details {
      flex-basis: 100%;
      width: 100%;
      height: auto;
      flex-direction: column;
      align-items: center;

      .case-detail-numbers {
        margin-left: 0px;
        align-items: center;
      }
      .case-item-preview-header-container {
        width: 100%;
      }
    }
  }

  &.mobile-two {
    // border: 1px solid #fff;

    .battle-cases-details {
      flex-basis: 100%;
      width: 100%;

      .case-item-preview-header-container {
        width: 100%;
      }
    }
  }

  @media (max-width: $breakPointOne) {
    .right {
      flex-basis: 100%;
      .game-status-container {
        width: 100%;
        margin-right: 0px;
      }
    }
  }
}
.vs-block {
  // background-color: #628bb0;

  padding: 5px;
  justify-content: center;
  align-items: center;
  // transform: rotate(-10deg);
  // margin-top: 300px;
  position: absolute;
  z-index: 11;
  top: 150px;
  background-color: #341a34;
  right: -26px;
  width: 52px;
  height: 52px;
  display: flex;
  border-radius: 50%;
  border: 2px solid #0c0e1b;

  // &.multi-item {
  //   top: 319px;
  // }

  @media (max-width: $breakPointTwo) {
    scale: 0.6;
  }
}
.game-lobby-container {
  padding-top: 25px;

  .heading-nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    position: relative;

    @media (max-width: $breakPointThree) {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }

    .background-gradient {
      position: absolute;
      width: 100px;
      height: 100px;
      left: -36px;
      // border: 1px solid #fff;
      background: radial-gradient(
        ellipse at center,
        #0078db33,
        rgba(255, 76, 76, 0) 50%
      );
    }

    .heading-name {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        width: 25px;
        height: 25px;
        path {
          fill: $blue;
        }
      }

      h1 {
        font-size: 18px;
        color: $primaryFontColor;
      }
    }

    svg path {
      fill: #fff;
    }
  }
}

.current-item-indicator {
  position: absolute;
  // border: 1px solid #fff;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  // width: 20px;

  &.lobby-display {
    left: 50px;
    .bottom {
      bottom: 12px;
    }
  }

  .bottom {
    position: absolute;
    transform: rotate(180deg) translateY(-12px);
  }
}

.battle-cases-details {
  // width: 300px;
  height: 100px;

  display: flex;
  // border: 1px solid #fff;

  .case-detail-numbers {
    margin-left: 20px;
    font-size: 13px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    gap: 5px;

    .bottom-details {
      display: flex;
      // align-items: center;
      flex-direction: column;
      gap: 5px;

      h3 {
        color: $white;
        font-size: 14px;
      }
    }

    h3 {
      color: $fontColorTwo;
      font-weight: 600;
      font-size: 12px;
    }
    .case-value-display {
      display: flex;
      align-items: center;
      gap: 5px;

      h3 {
        color: #fff;
      }
    }
  }

  .case-item-preview-header-container {
    // display: flex;
    height: 100%;
    width: 300px;
    border-radius: $radius;
    // border: 1px solid #fff;
    position: relative;
    overflow: hidden;
    background-color: #11152ccd;

    display: flex;
    justify-content: center;

    .case-item-preview-shadow-left {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100px;
      background: linear-gradient(to right, #11152c, #11152c00);
    }
    .case-item-preview-shadow-right {
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      background: linear-gradient(to left, #11152c, #11152c08);
    }

    .cases-header-list {
      transition: transform 0.5s ease; /* Smooth transition for shifting */
      display: flex;
      flex-wrap: nowrap;
      // gap: 10px;
      // margin-left: 100px;
      // border: 1px solid pink;
    }
    .case-item-preview {
      // display: inline-block;
      // position: relative;
      // flex-basis: 100px;
      // // flex: 1 1 0;
      // width: 100px;
      // height: 100px;
      display: inline-block; /* Inline-block is fine, but flex items default to block */
      position: relative;
      width: 100px; /* Fixed width for each item */
      height: 100px;
      /* Ensure items do not shrink or grow beyond their set dimensions */
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}

//Target the first and last children here
.main-spinner-section {
  .player-section {
    &:first-child {
      .user-details {
        margin-left: 0px;
      }
      .case-detail-display {
        padding-left: 0px;
      }
      .main-spinner-gameplay-container {
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
      }
    }
    &:last-child {
      .user-details {
        margin-right: 0px;
      }
      .case-detail-display {
        padding-right: 0px;
      }
      .main-spinner-gameplay-container {
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
      }
    }

    &:first-child {
      // border: 1px solid #fff;
      //  Curve the edges of the first spinner
      .vertical {
        overflow: hidden;
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
        padding-left: 0px;
      }
    }
    &:last-child {
      .vertical {
        overflow: hidden;
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
        padding-right: 0px;
      }
    }
  }

  &.four-player-mobile {
    // border: 1px solid #fff;
    display: grid;
    grid-template-columns: repeat(2, 1fr); // Switches to two columns
    .player-section {
      // flex: 0 0 50%;
      // display: grid;
      width: 100%;
      &:nth-child(2) {
        // border: 1px solid #fff;
        .vs-block {
          display: none;
          // Target every even vs-block
          // Add your styles here
        }
      }
    }
  }

  &.three-player-mobile {
    flex-wrap: wrap;

    .player-section {
      &:nth-child(2) {
        .vs-block {
          display: none;
        }
      }

      &:nth-child(3) {
        flex-basis: 100%;
      }
    }
  }
}

.user-details {
  // background-color: $secondaryBackgroundColor;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 83px;
  background: radial-gradient(
    ellipse farthest-corner at 50% 120%,

    #262c52,
    #262c521f
  );
  padding-left: 20px;
  padding-right: 20px;
  // border: 1px solid #fff;
  margin-top: 18px;
  margin-right: 2px;
  margin-left: 2px;
  border-radius: $radius;

  &.in-spinner {
    margin-top: 0px;
    .player-display {
      flex-direction: column;
      gap: 15px;
    }
    background: transparent;
  }

  .house-icon {
    svg {
      width: 40px;
      height: 40px;
      height: auto;
    }
  }

  &.blue {
    // background: radial-gradient(
    //   circle at center,
    //   #0078db11,
    //   rgba(255, 76, 76, 0) 50%
    // );

    .player-display .user-in-lobby .foreground-circle {
      background-color: $BattleBlue;
    }
    .player-display .user-in-lobby .background-shape svg path {
      fill: $BattleBlue;
    }
  }

  &.red {
    // background: radial-gradient(
    //   circle at center,
    //   #ff4c4c1c,
    //   rgba(255, 76, 76, 0) 50%
    // );
    .player-display .user-in-lobby .foreground-circle {
      background-color: $BattleRed;
    }
    .player-display .user-in-lobby .background-shape svg path {
      fill: $BattleRed;
    }
  }

  &.green {
    // background: radial-gradient(
    //   circle at center,
    //   #84f0741a,
    //   rgba(255, 76, 76, 0) 50%
    // );
    .player-display .user-in-lobby .foreground-circle {
      background-color: $BattleGreen;
    }
    .player-display .user-in-lobby .background-shape svg path {
      fill: $BattleGreen;
    }
  }

  &.yellow {
    // background: radial-gradient(
    //   circle at center,
    //   #ffd64f15,
    //   rgba(255, 76, 76, 0) 50%
    // );
    .player-display .user-in-lobby .foreground-circle {
      background-color: $BattleYellow;
    }
    .player-display .user-in-lobby .background-shape svg path {
      fill: $BattleYellow;
    }
  }

  h3 {
    font-size: 14px;
    color: $white;
    // padding-left: 20px;
  }

  .player-display {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;

    .user-in-lobby {
      width: 40px;
      height: 40px;
      position: relative;
      border: none;

      transition: background-color 0.3s ease-in-out;
      &.join-btn {
        &:hover {
          cursor: pointer;
          background-color: #191f3b;
        }
      }

      .center-icon {
        height: 15px;
        width: 15px;
        svg {
          width: 15px;
          height: 15px;
          path {
            fill: #fff;
          }
        }
      }
      .foreground-circle {
        /* Ellipse 32 */
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        position: absolute;
        // width: 80px;
        // height: 80px;
        // border: 3px solid #9aa0c1;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        z-index: 1;

        // background: rgba(0, 0, 0, 0.4);
      }
      .background-shape {
        // z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .user-display-icon {
        z-index: 2;
      }
    }

    .bot-badge {
      font-size: 10px;
      border: 1px solid $blue;
      border-radius: $radius;
      padding: 5px;
      // margin-left: 10px;
    }
  }

  .join-game-display {
    gap: 20px;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: $breakPointTwo) {
    // border: 1px solid #fff;
    height: 100px;
    flex-direction: column;
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;

    .player-display {
      scale: 0.8;
      // flex-direction: column;
    }
  }
  @media (max-width: $breakPointThree) {
    // border: 1px solid #fff;
    height: 130px;
    flex-direction: column;
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;

    .player-display {
      scale: 0.8;
      flex-direction: column;
    }

    .bot-badge {
      display: none;
    }
  }
}

.user-details-slot {
  // background-color: $secondaryBackgroundColor;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 83px;
  background: radial-gradient(
    ellipse farthest-corner at 50% 120%,
    #262c52,
    #262c521f
  );
  padding-left: 20px;
  padding-right: 20px;
  // border: 1px solid #fff;
  margin-top: 18px;
  margin-right: 2px;
  margin-left: 2px;
  border-radius: $radius;

  &.in-spinner {
    margin-top: 0px;
    .custom-slot-player-display {
      flex-direction: column;
      gap: 15px;
    }
    background: transparent;
  }

  &.double-down-slot {
    margin-top: 0px;
    .custom-slot-player-display {
      flex-direction: column;
      gap: 0px;
    }
    background: transparent;

    .house-icon {
      svg {
        width: 35px;
        height: 35px;
        height: auto;
      }
    }
  }

  .house-icon {
    svg {
      width: 40px;
      height: 40px;
      height: auto;
    }
  }

  h3 {
    font-size: 14px;
    color: $white;
    // padding-left: 20px;
  }

  .custom-slot-player-display {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;

    .user-in-lobby {
      width: 40px;
      height: 40px;
      position: relative;
      border: none;

      transition: background-color 0.3s ease-in-out;

      .foreground-circle {
        /* Ellipse 32 */
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        position: absolute;
        // width: 80px;
        // height: 80px;
        // border: 3px solid #9aa0c1;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        z-index: 1;

        // background: rgba(0, 0, 0, 0.4);
      }
      .background-shape {
        // z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .user-display-icon {
        z-index: 2;
      }
    }
  }
}

.battle-room-container {
  position: relative;

  opacity: 0;
  transform: translateY(20px);

  transition:
    opacity 0.3s ease-in-out,
    transform 0.4s ease-in-out;

  .loading-container {
    // position: absolute;
    z-index: 10;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #191f3b;
    border-radius: $radius;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    .loading-spinner {
      position: relative;
      margin-top: 0px;
    }
  }

  // &.mobile {
  //   .case-heading {
  //     flex-direction: column;
  //     gap: 10px;
  //   }

  //   .main-spinner-section {
  //     // flex-direction: column;
  //     // flex-wrap: wrap;
  //     display: grid;
  //     grid-template-columns: repeat(2, 1fr); // Switches to two columns
  //     .player-section {
  //       // flex: 0 0 50%;
  //       // display: grid;
  //       // width: 100%;
  //     }
  //     .vs-block {
  //       position: relative;
  //       top: 0px;
  //       display: flex;
  //       justify-content: center;
  //       left: 0;
  //       &.multi-item {
  //         top: 0px;
  //       }
  //     }
  //   }
  // }
}
.seed-value-display {
  margin-top: 50px;
  padding: 5px;

  .seed-heading span {
    font-weight: 500;
    font-size: 16px;
    color: #c3c4c9;
    display: block;
    margin-bottom: 10px;
  }
  .row-item {
    margin-bottom: 5px;
    span {
      opacity: 0.75;
      font-size: 14px;
    }
    .row-name {
      opacity: 0.5;
    }
  }
}

.main-spinner-section {
  display: flex;
  width: 100%;
  align-items: flex-start;
  // gap: 20px;
  overflow: hidden;
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
  justify-content: center;
  position: relative;
  justify-content: space-evenly;

  .player-section {
    width: 50%;
    flex-grow: 1;
    position: relative;

    .player-color {
      width: 100%;
      height: 10px;
      background-color: $blue;
    }
    .winnings-display-container {
      .podium-container {
        padding-left: 7px;
        padding-right: 7px;
        margin-bottom: -4px;
        svg {
          width: 100%;
          height: auto;
        }
        width: 100%;
      }
    }

    .player-chest-winnings {
      display: flex;
      justify-content: center;
      padding: 15px;
      background-color: $secondaryBackgroundColor;
      margin-right: 5px;
      margin-left: 5px;

      .amount-icon {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 16px;
        font-weight: bold;
        color: $fontColorTwo;
      }
      //background gradient

      // background: linear-gradient(to right, #090c20, rgba(38, 46, 58, 0));

      background: linear-gradient(to top, #232439, #1a203c);

      border-radius: $radius;
    }
  }
}

.player-section {
  // width: 33%;
  h2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.battles-progress-bar {
  margin-top: 10px;
  width: 100%;
  height: 10px;
  overflow: hidden;
  border-radius: 3px;
  display: flex;
  margin-bottom: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.active {
    opacity: 1;
  }
  // border: 1px solid #fff;

  .progress-bar__inner__progress {
    // display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    transition: width 0.3s ease-in-out;
    span {
      font-size: 12px;
      font-weight: bold;
      color: #080420;
      // border: 1px solid #000;
      // border-color: red; // Replace "red" with your desired border color
    }
  }
}

.battles-progress-bar-mini {
  left: 10px;
  right: 10px;

  height: 5px;
  overflow: hidden;
  border-radius: 2px;
  display: flex;

  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  bottom: 0;
  &.active {
    opacity: 1;
  }

  // border: 1px solid #fff;

  .progress-bar__inner__progress {
    // display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    transition: width 0.3s ease-in-out;
    span {
      font-size: 12px;
      font-weight: bold;
      color: #080420;
      // border: 1px solid #000;
      // border-color: red; // Replace "red" with your desired border color
    }
  }
}

//Room display
.game-rooms-list-display {
  padding-top: 20px;

  &.mobile-one,
  &.mobile-two {
    // border: 1px solid #fff;

    .game-rooms-list {
      .game-room {
        .game-room-right {
          flex-direction: column;
          justify-content: center;
          width: 20%;
        }
        .game-room-players {
          // border: 1px solid #fff;
          flex-basis: 35%;
        }
        .game-room-left {
          .count {
            scale: 0.7;
          }
        }
      }
    }
  }
  &.mobile-three {
    // border: 1px solid #fff;

    .game-rooms-list {
      .game-room {
        flex-direction: column;
        height: auto;
        .game-room-right {
          padding-top: 20px;
          justify-content: center;
          width: 100%;
        }
        .game-room-players {
          // border: 1px solid #fff;
          flex-basis: 35%;
          padding-bottom: 20px;
          width: 100%;
          padding-bottom: 20px;
        }
        .game-room-left {
          .count {
            scale: 0.7;
          }
        }

        .vertical-divider {
          display: none;
        }
      }
    }
  }

  @media (max-width: $breakPointOne) {
    .game-rooms-list {
      .game-room {
        flex-direction: column;
        height: auto !important;
        .game-room-right {
          padding-top: 20px;
          justify-content: center !important;
          width: 100%;
        }
        .game-room-players {
          // border: 1px solid #fff;
          flex-basis: 35%;
          padding-bottom: 20px;
          width: 100% !important;
          justify-content: center !important;
          padding-bottom: 20px;
        }
        .game-room-left {
          .count {
            scale: 0.7;
          }
        }

        .vertical-divider {
          display: none;
        }
      }
    }
  }

  .heading-separator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    gap: 10px;
    h3 {
      font-size: 11px;
      color: #ffffff58;
      flex-shrink: 0;
    }
    .separator-line {
      height: 1px;
      width: 100%;
      flex-shrink: 1;
      background-color: #ffffff58;
    }
  }

  h2 {
    padding-bottom: 20px;
  }
  .game-rooms-list {
    display: flex;
    flex-basis: 50%;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .empty-slot {
      width: 100%;
      height: 100px;
      border-radius: $radius;
      background-color: $secondaryBackgroundColor;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      font-size: 14px;
      color: $fontColorTwo;
      height: 150px;
      position: relative;
      overflow: hidden;

      .center-content {
        display: flex;
        flex-direction: column;
        gap: 10px;

        svg path {
          fill: #fff;
        }
      }

      .removal-progress {
        width: 100%;
        height: 5px;
        overflow: hidden;
        background-color: $blue;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
      }
    }

    .game-room {
      height: 150px;
      width: 100%;
      background-color: $secondaryBackgroundColor;
      padding: 14px;
      // background: linear-gradient(to bottom, rgb(91, 99, 141, 0.5), #5b638d);
      // padding: 20px;
      border-radius: $radius;
      display: flex;
      justify-content: flex-end;
      gap: 2px;
      position: relative;

      //Making room for the bar
      &.in-game {
        // .game-room-players {
        //   padding-top: 20px;
        //   transition: padding 0.3s ease-in-out, margin 0.3s ease-in-out;
        // }

        .game-room-modes {
          transition:
            padding 0.3s ease-in-out,
            margin 0.3s ease-in-out;
          margin-bottom: 15px;
        }
      }

      // &.regular {
      //   background: radial-gradient(
      //     farthest-corner ellipse at bottom,
      //     #ff4c4c70,
      //     $secondaryBackgroundColor 80%
      //   );
      //   // .right-fade {
      //   //   background: linear-gradient(to right, #ff4c4c00, #ff4c4c2c);
      //   // }
      // }

      // &.team {
      //   background: radial-gradient(
      //     farthest-corner ellipse at bottom,
      //     #0078db83,
      //     $secondaryBackgroundColor 80%
      //   );
      // }
      // &.group {
      //   background: radial-gradient(
      //     farthest-corner ellipse at bottom,
      //     #84f0746d,
      //     $secondaryBackgroundColor 80%
      //   );
      // }

      .game-room-left {
        // width: 50%;

        display: flex;
        flex-grow: 1;
        // border: 1px solid red;
        overflow: hidden;
        gap: 5px;
        .game-rounds {
          width: 20%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;

          .round-status {
            position: absolute;
            bottom: 10px;
            gap: 5px;
            font-size: 11px;
            color: $fontColorTwo;
          }
          .count {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            background-color: $primaryBackgroundColor;
            // border-radius: $radius;
            transform: rotate(-45deg);
            border: 2px solid #5e679eb1;
            margin-bottom: 20px;

            .inner-container {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #0f1324;
              width: 46px;
              height: 46px;
              background: linear-gradient(
                45deg,
                rgba(20, 25, 49, 0) 43,
                #485ab343
              );
              // border-radius: $radius;
            }

            .round {
              display: flex;
              justify-content: center;
              align-items: center;
              // border-radius: $radius;
              width: 38px;
              height: 38px;
              // background-color: $primaryBackgroundColor;
              background: linear-gradient(45deg, #141931, #20284f);
              border: 2px solid #5e679e;
              span {
                transform: rotate(45deg);
                font-size: 14px;
                font-weight: bold;
              }
            }
          }
        }
      }

      .game-room-cases-container {
        width: 80%;
        overflow: hidden;
        display: flex;
        align-items: center;
        position: relative;
        background-color: rgba(15, 19, 40, 0.7);
        border-radius: $radius;

        .round-counter {
          position: absolute;
          right: 10px;
          top: 10px;
          padding: 5px;
          border-radius: $radius;
          background-color: #e8e5ff10;
          backdrop-filter: blur(5px);
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 10px;
            color: #e8e5ff77;
          }
        }

        .right-fade {
          z-index: 1;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100px;
          pointer-events: none;
        }
      }

      .game-room-cases {
        display: flex;
        // overflow-x: hidden;
        white-space: nowrap;
        transition: transform 0.5s ease; /* Smooth transition for shifting */
        align-items: center;
      }

      .case-item-preview {
        flex: 0 0 auto; /* Ensure that cases don't shrink or grow */
        width: 100px;
        opacity: 0.3;
        transition: opacity 0.3s ease-in-out;
        // border: 1px solid #fff;
        .case-image {
          border: none;
          width: 100px;
          height: 100px;

          &:hover {
            cursor: pointer;
          }
        }

        &.current-case {
          opacity: 1;
        }
      }
      .game-room-players {
        display: flex;
        flex-direction: column;
        gap: 13px;
        width: 25%;
        flex-basis: 25%;
        flex-shrink: 0;
        padding-top: 20px;
        align-items: center;

        position: relative;

        .heading {
          display: flex;
          align-items: center;
          gap: 10px;

          .mode-icon {
            width: 20px;
            flex-shrink: 0;
            svg {
              width: 100%;
              height: 100%;
            }
          }

          .mode-names {
            display: flex;
            gap: 5px;

            .terminal {
              border: 1px solid #ffe6004f;
              background-color: #ffe60028;
            }
            .chest-multiplier {
              background-color: #ff8c193f;
              border: 1px solid #ff8c19b9;
            }
            .loser-wins {
              background-color: #f8676740;
              border: 1px solid #f8676791;
            }
            .name {
              width: 24px;
              height: 24px;
              // border: 1px solid #fff;
              border-radius: 3px;
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                width: 80%;
                height: 80%;
              }
            }
            .name span {
              font-size: 11px;
            }
          }
        }
        .heading h3 {
          text-transform: capitalize;
          color: $fontColor;
          width: 100%;
          display: flex;

          gap: 15px;
          // font-weight: bold;
          font-size: 12px;
          .value-display {
            display: flex;
            gap: 5px;
            align-items: center;
            color: #fff;
          }
        }
        .rules {
          font-size: 13px;
          padding-top: 5px;
        }

        .game-progress-bar {
          position: absolute;
          // border: 1px solid #fff;
          width: 100%;
          bottom: 0;
          z-index: 10;
        }
      }
    }

    .game-room-users {
      display: flex;
      gap: 7px;
      align-items: center;
      z-index: 1;
    }

    .divider {
      svg {
        width: 12px;
        height: auto;

        path {
          fill: #69729d;
        }
      }

      &.big {
        svg {
          width: 17px;
          height: auto;

          path {
            fill: #69729d;
          }
        }
      }
      // width: 5px;
      // height: 5px;
      // border: 1px solid #fff;
    }

    .game-room-player {
      display: flex;
      align-items: center;

      // gap: 15px;
    }

    .game-room-modes {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      position: absolute;
      flex-direction: column;
      margin-bottom: 10px;

      // border: 1px solid #fff;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .icons {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: center;
        // border: 1px solid #fff;

        .icon-container {
          svg {
            width: 60px;
            height: auto;
            path {
              fill: rgba(255, 255, 255, 0.05);
            }
          }
        }
      }
    }

    .game-room-right {
      flex-shrink: 0;
      display: flex;
      // padding-top: 20px;
      // flex-direction: column;
      align-items: center;
      padding-right: 10px;
      padding-left: 20px;

      justify-content: space-between;
      gap: 10px;
      border-top-right-radius: $radius;
      border-bottom-right-radius: $radius;
      // background-color: rgb(255, 255, 255, 0.04);

      width: 250px;
      // border: 1px solid #fff;

      @media (max-width: $breakPointOne) {
        padding: 10px;
        width: 100% !important;
      }

      .game-description {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        padding-left: 10px;
        padding-right: 10px;

        .funding-indicator {
          display: flex;
          align-items: center;
          gap: 5px;
          svg {
            width: 18px;
          }
          span {
            font-size: 12px;
          }
        }

        .value-display {
          display: flex;
          gap: 5px;

          font-size: 12px;
        }

        h3 {
          font-size: 13px;
          font-weight: bold;
          text-transform: capitalize;
        }
      }

      .game-status {
        h3 {
          font-size: 12px;
        }
      }
    }
  }
  .case-item-preview {
    &:hover {
      cursor: pointer;
    }
    // height: 100px;
    // width: 100px;
    display: flex;
    // align-items: center;
    text-align: center;
    flex-direction: column;
    .case-image {
      width: 60px;
      height: 80px;
      position: relative;
      // margin-top: 10px;
    }
    p {
      font-size: 12px;
    }
    // border: 1px solid #fff;
  }
}
.user-in-lobby {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  // background-color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  // border-color: #5e679e;
  border-color: transparent;

  &.winner {
    transition: box-shadow 0.3s ease-in-out;
  }

  &.loser {
    transition: opacity 500ms $easeInOut3;
    opacity: 0.3;
  }

  &.user-icon-showing {
    svg {
      width: 15px;
      margin-right: -1px;
    }
  }

  svg {
    width: 20px;
  }
}
.config-container {
  opacity: 0;
  transform: translateY(20px);

  transition:
    opacity 0.3s ease-in-out,
    transform 0.4s ease-in-out;

  h1 {
    font-size: 14px;
    color: $primaryFontColor;
  }
}
.configure-crate-battle-container {
  width: 100%;
  margin-bottom: 20px;
  border-radius: $borderRadius;
  // background-color: $secondaryBackgroundColor;
  margin-top: 10px;
  // padding: $padding;

  .back-heading-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 14px;
    color: $primaryFontColor;
  }
  h1 {
    font-size: 18px;
    color: $primaryFontColor;
  }
  p {
    font-size: 13px;
    color: $font-color-two;
  }

  .team-configuration {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    // flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    padding-top: 20px;

    &.wrap {
      flex-direction: column;

      > div {
        width: 100%;

        .toggle-bottons-container {
          display: inline-flex;
        }
      }
    }

    @media (max-width: $breakPointOne) {
      flex-direction: column;
    }

    > div {
      border-radius: 6px;
      // padding: 20px;
      // display: flex;
      width: 33%;
      gap: 10px;
      background-color: #262c52;
      flex-direction: column;
      padding: 30px;
      display: flex;
      justify-content: space-between;

      @media (max-width: $breakPointOne) {
        width: 61%;
      }

      @media (max-width: $breakPointTwo) {
        width: 100%;
      }

      .heading {
        padding-bottom: 20px;

        .title {
          display: flex;
          gap: 8px;
          align-items: center;
          padding-bottom: 8px;
          color: $primaryFontColor;

          svg {
            width: 25px;
            height: auto;
          }
        }
        p {
          font-size: 13px;
        }
      }
      .toggle-bottons-container {
        // width: 100%;
        background-color: $btn-fill-color;
        display: flex;
        border-radius: $radius;
        gap: 10px;

        @media (max-width: $breakPointThree) {
          flex-direction: column;
        }
      }
      .toggle-bottons-container-one-item {
        // width: 100%;
        background-color: $btn-fill-color;
        display: inline-flex;
        border-radius: $radius;

        @media (max-width: $breakPointThree) {
          flex-direction: column;
        }
      }
    }
  }
  .battle-options-container {
    h2 {
      color: $primaryFontColor;
      padding-bottom: 10px;
    }
    .battle-options {
      display: flex;
      gap: 20px;
      padding-bottom: 20px;
      justify-content: space-between;

      @media (max-width: $breakPointOne) {
        flex-direction: column;
      }

      .text-content {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      .toggle-container {
        width: 33%;
        border-radius: $radius;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        background-color: #262c52;
        align-items: center;
        position: relative;
        overflow: hidden;
        gap: 20px;
        flex-shrink: 1;

        @media (max-width: $breakPointOne) {
          width: 61%;
        }
        @media (max-width: $breakPointTwo) {
          width: 100%;
        }

        .background-effect {
          position: absolute;
          // z-index: -1;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          opacity: 0;
          pointer-events: none;
          transition: opacity 500ms $easeInOut3;
          // border: 1px solid #fff;

          &.active {
            opacity: 1;
          }

          &.terminal {
            background: linear-gradient(
              45deg,
              $terminalColor,
              1%,
              rgba(255, 255, 255, 0)
            );
          }

          &.loser-wins {
            background: linear-gradient(
              45deg,
              $loserWinsColor,
              1%,
              rgba(255, 255, 255, 0)
            );
          }
          &.chest {
            background: linear-gradient(
              45deg,
              $multiplierColor,
              1%,
              rgba(255, 255, 255, 0)
            );
          }
        }

        .description {
          z-index: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 4px;
          > div {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            color: $font-color-two;
          }
        }
        .icon {
          z-index: 1;
        }
        .toggle-select {
          z-index: 1;
        }
      }
    }
  }

  .additional-settings-container {
    display: flex;
    align-items: center;
    gap: 40px;

    @media (max-width: $breakPointTwo) {
      flex-direction: column;
    }

    > div {
      &.not-allow {
        opacity: 0.5;
        pointer-events: none;
      }
      display: flex;
      gap: 10px;
      align-items: center;
      h3 {
        color: $primaryFontColor;
        font-size: 14px;
      }
    }
  }
}
.submit-container {
  display: flex;
  gap: 20px;
  padding-top: 20px;
  // justify-content: flex-start; // Align content to the very left
  width: 100%;
  justify-content: space-between;
  // border: 1px solid #fff;
  align-items: flex-end;

  @media (max-width: $breakPointTwo) {
    flex-direction: column;

    .funding-slider-container {
      width: 100%;
      .slider {
        width: 100% !important;
      }
      &.visible {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .funding-slider-container {
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.3s ease-in-out;
    &.visible {
      opacity: 1;
      pointer-events: all;
    }

    h3 {
      font-size: 14px;
      color: $primaryFontColor;
      padding-bottom: 10px;
    }
    .slider {
      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: $radius;
      padding: 15px;
      background-color: $secondaryBackgroundColor;
      width: 300px;

      h3 {
        padding: 0;
        width: 55px;
      }
      input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 15px;
        border-radius: 5px;
        background: $blue;
        outline: none;
      }

      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        background: #fff;
        cursor: pointer;
        border-radius: 50%;
      }
    }
  }

  .submit-content {
    display: flex;
    gap: 20px;

    @media (max-width: $breakPointTwo) {
      flex-direction: column;
      width: 100%;
    }

    .funding-popup {
      position: absolute;
      background-color: $secondaryBackgroundColor;
      border-radius: $radius;
      width: 120px;
      height: 50px;
      opacity: 0.5;
      right: 0;
      bottom: 50px;
      padding: 10px;
      opacity: 0;
      pointer-events: none;

      transition: opacity 0.3s ease-in-out;

      @media (max-width: $breakPointTwo) {
        position: relative;
        width: 100%;
        bottom: 0;
      }

      &.visible {
        opacity: 1;
        pointer-events: all;
      }
      display: flex;
      flex-direction: column;
      > div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        gap: 5px;
        svg {
          width: 15px;
        }
        span {
          font-size: 12px;
          color: $primaryFontColor;
        }
      }
      .funding-row {
        justify-content: space-between;
      }
    }

    .total-value {
      display: flex;
      align-items: center;
      gap: 5px;
      // border: 1px solid #fff;
      position: relative;
      @media (max-width: $breakPointTwo) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
.drag-drop-case-list-container {
  // display: grid;

  &:hover {
    cursor: move;
  }
  &.dragging {
    cursor: grabbing !important;
    // border: 1px solid #fff;
    z-index: 10;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  }
  // grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

.case-list-container {
  .case-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .mobile-interaction-instruction {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .case-list {
    display: grid;
    // display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    // display: grid;

    gap: 20px;
    // grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    margin-bottom: 20px;
    // grid-auto-rows: 280px;

    // > div {
    //   display: flex;
    //   gap: 20px;
    // }

    .add-cases-btn {
      height: 245px;
      width: 160px;
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      // background: linear-gradient(to bottom, #0c0e1b, rgba(38, 46, 58, 0));

      .plus-icon {
        background-color: rgba(94, 103, 158, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
      }

      span {
        font-size: 14px;
        color: $primaryFontColor;
      }
    }
  }
}

.case-open-spinner-container {
  width: 100%;
  background-color: #0c0e1b;
  // padding: 50px 40px 0 40px;
  // padding-top: 20px;
  // display: flex;

  &.mobile-two,
  &.mobile-one {
    .center-content {
      flex-direction: column;
      gap: 20px;
    }
  }
  // The vertical spinner--------------------------------
  &.vertical {
    .case-open-real-container {
      // .case-open-real {
      //   .real-content {
      //     flex-direction: row;
      //   }
      // }
      border-radius: 0;
      .case-open-real {
        height: 350px;

        // &.complete-state {
        //   transition: opacity 0.3s ease;
        //   opacity: 0.2;
        //   scale: 0.99;
        // }
      }
      .real-landing-line {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        bottom: 0;
        width: 100%;
        height: 5px;
        transform: translate(0, -50%);
        background-color: #ffa000;
      }
      .top-side-fade {
        position: absolute;
        width: 100%;
        height: 50px;
        z-index: 1;
        top: 0;
        background: linear-gradient(to bottom, #0c0e1b, rgba(38, 46, 58, 0));
      }
      .bottom-side-fade {
        position: absolute;
        width: 100%;
        height: 50px;
        z-index: 1;
        bottom: 0;
        top: auto;
        background: linear-gradient(to top, #0c0e1b, rgba(38, 46, 58, 0));
      }
      .case-open-real .real-content {
        height: auto;

        &.complete-state {
          transition: scale 0.3s ease;
        }
        // scale: 0.9
      }
    }
  }

  // Double down spinner and tie breaker
  &.top-spinner {
    opacity: 0;
    transition: opacity 0.3s ease;
    height: 0;

    .case-open-real-container {
      margin-top: 20px;
      margin-bottom: 20px;
      .case-open-real {
        height: 200px;

        .real-content .case-item {
          height: 150px;
        }
      }
    }
  }

  &.double-down-spinner {
    background-color: transparent;
    mask-image: linear-gradient(
      to right,
      transparent,
      black 30%,
      black 70%,
      transparent
    );
    .case-open-real-container {
      .left-side-fade {
        display: none;
      }

      .right-side-fade {
        display: none;
        background: linear-gradient(to left, #84f074a7, rgba(132, 240, 116, 0));
      }

      .case-open-real {
        background-color: transparent;
        height: 120px;
      }
    }
  }

  &.multi-case {
    width: auto;
    height: 100%;
    // margin-bottom: 20px;
    // margin-top: 20px;
    // margin-right: 10px;
    // margin-left: 10px;
    // border-radius: $radius;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0px;
    background-color: transparent;
    z-index: 1;
    // border: 1px solid pink;

    .case-open-real-container {
      height: 100%;
      border-radius: 0;
      .left-side-fade {
        width: auto;
        // border: 1px solid #fff;
        bottom: auto;
        left: 0;
        right: 0;
        top: 0;
        height: 125px;
        background: linear-gradient(
          to bottom,
          $primaryBackgroundColor,
          rgba(38, 46, 58, 0)
        );
        border-radius: 0px !important;
      }
      .right-side-fade {
        border-radius: 0px !important;
        width: auto;
        // border: 1px solid #fff;
        bottom: 0;
        left: 0;
        right: 0;
        top: auto;
        height: 125px;
        background: linear-gradient(
          to top,
          $primaryBackgroundColor,
          rgba(38, 46, 58, 0)
        );
      }

      .real-landing-line {
        // top: 0;
        // left: 50%;
        width: 100%;
        transform: translate(0, -50%);
        left: 0;
        right: 0;
        height: 5px;
        top: 50%;
      }
      .case-open-real {
        height: 100%;
        background: transparent;
        .real-content {
          position: relative;
          flex-direction: column;
          height: auto;
          transform: translate(0, 1200px);
        }

        .real-content .case-item {
          height: 50px;
        }
      }
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .case-open-real-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    // margin-bottom: $padding;
    // margin-top: $padding;
    border-radius: $radius;
    overflow: hidden;

    .left-side-fade {
      border-radius: $radius;
      // border: 1px solid #fff;
      pointer-events: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 200px;
      z-index: 5;
      background: linear-gradient(to right, #0c0e1b, rgba(38, 46, 58, 0));
    }
    .right-side-fade {
      border-radius: $radius;
      pointer-events: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 200px;
      z-index: 5;
      background: linear-gradient(to left, #0c0e1b, rgba(38, 46, 58, 0));
    }

    .real-landing-line {
      position: absolute;
      z-index: 2;
      // height: 200px;
      top: -10px;
      left: 50%;
      // border-radius: $radius;
      // @include gradient(80deg, $darkPurple, $pink);
      bottom: -10px;
      display: flex;
      width: 5px;
      justify-content: center;
      // background-color: red;
      background-color: #ffc255;
      // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }

    .case-open-real {
      width: 100%;
      height: 242px;
      // padding-top: 10px;
      // padding-bottom: 5px;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;
      // border: 1px solid #fff;
      align-items: center;
      justify-content: center;
      display: flex;
      background-color: #0c0e1b;
      // box-shadow: inset 0 0 5px rgb(38, 38, 38);
      padding-top: 6px;
      padding-bottom: 6px;

      .real-content {
        // width: 5000px;
        position: absolute;
        height: 100%;
        display: flex;

        align-items: center;
        bottom: 0;
        // top: 50%;
        // transform: translate(1000px, -50%, 0);
        // transform: translateY(-50%);
        transform: translateX(1000px);
        gap: 6px;

        &.vertical {
          padding-left: 20px;
          padding-right: 20px;
          flex-direction: column;
          gap: 10px;
          // width: 90%;

          transform: translateY(1500px);

          .case-item {
            width: 200px;
            height: 200px;
          }

          @media screen and (max-width: 768px) {
            // .case-item {
            //   width: 150px;
            //   height: 150px;
            // }
          }
        }

        .case-item {
          will-change: transform;
          transform: translateZ(0);
          // background-color: #432729;

          &.double-down {
            background: transparent;
            width: 100px;
            height: 100px;
            // backdrop-filter: blur(1px);
            background: radial-gradient(
              ellipse farthest-corner at 50% 120%,

              #e8e5ff50,
              #bbb2ff2f
            );
          }
          // @include gradient(to top, #2f3a56, #252e4a);

          background: radial-gradient(
            ellipse farthest-corner at 50% 120%,

            #262c52,
            #262c521f
          );

          box-shadow: 0px -4px 0px 0px #69819c inset;
          // box-shadow: 0px -3px 0px 0px rgb(29, 15, 54, 1), inset;
          &.uncommon {
            @include gradient(
              to top,
              rgba(48, 61, 75, 1),
              rgba(48, 61, 75, 0.3)
            );
            box-shadow: 0px -4px 0px 0px rgb(78, 80, 82) inset;
          }

          // Purple items
          &.mythical {
            @include gradient(to top, #3b1f70, rgba(59, 31, 112, 0.3));
            box-shadow: 0px -4px 0px 0px #4b0ebc inset;
            background-origin: border-box;
          }

          // Rare items
          &.ancient {
            @include gradient(
              to top,
              rgba(89, 50, 52, 1),
              rgba(89, 50, 52, 0.3)
            );
            background-origin: border-box;
            box-shadow: 0px -4px 0px 0px rgb(86, 34, 37) inset;
          }

          // Legendary items
          &.immortal {
            @include gradient(
              to top,
              rgba(131, 96, 45, 1),
              rgba(131, 96, 45, 0.3)
            );
            background-origin: border-box;
            box-shadow: 0px -4px 0px 0px rgb(156, 105, 28) inset;
          }

          // MULTI COLORS
          &.multi-red {
            background-origin: border-box;
            @include gradient(to top, #69313d, #562c2c);
            box-shadow: 0px -3px 0px 0px #da2828 inset;
          }
          &.multi-dark-red {
            box-shadow: 0px -3px 0px 0px #eb4b4b inset;
            @include gradient(to top, #522b40, #39263e);
            background-origin: border-box;
          }
          &.multi-green {
            @include gradient(
              to top,
              rgb(45, 131, 78),
              rgba(114, 248, 123, 0.3)
            );
            background-origin: border-box;
            box-shadow: 0px -4px 0px 0px rgb(18, 173, 46) inset;
          }
          &.multi-dark-green {
            @include gradient(
              to top,
              rgb(22, 144, 69),
              rgba(44, 181, 54, 0.701)
            );
            background-origin: border-box;
            box-shadow: 0px -4px 0px 0px rgb(15, 98, 38) inset;
          }

          &.multi-item {
            // background: linear-gradient(
            //   to top,
            //   rgb(24, 24, 24),
            //   rgba(0, 0, 0, 0.3)
            // );
            // background-color: #fff;
            // box-shadow: 0px -4px 0px 0px rgb(0, 0, 0) inset;

            .multi-display {
              font-size: 14px;
              font-style: normal;
              // font-weight: 600;
              line-height: normal;
              text-transform: uppercase;
              color: #fff;
              // background: linear-gradient(180deg, #8590d8 0%, #fff 100%);
              // background-clip: text;
              // -webkit-background-clip: text;
              // -webkit-text-fill-color: transparent;
              &.green {
                background: linear-gradient(0deg, #22c753 0%, #c5e4b7 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              &.red {
                background: linear-gradient(0deg, #bb2222 0%, #fa9797 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }

          // height: 220px;

          border-radius: $radius;
          width: 180px;
          box-sizing: border-box;
          padding: $Dmw/4;
          display: flex;
          align-items: center;
          align-content: center;
          position: relative;
          flex-direction: column;
          justify-content: center;
          background-origin: border-box;

          &.winning-item {
            z-index: 20;
            background-color: #fff;

            .multi-item {
              background-color: #fff;
            }
          }

          .item-name {
            // position: absolute;
            top: 20px;
            z-index: 2;
            // padding-bottom: 20px;
            color: $fontColor;
            font-size: $fontSizeFour;
            font-family: $boldFont;
            text-align: center;

            padding-left: 20px;
            padding-right: 20px;
            white-space: nowrap; // Prevent the text from wrapping
            overflow: hidden; // Hide any text that overflows the element
            text-overflow: ellipsis;
            bottom: 0;
            left: 0;
            right: 0;
            transform: translateY(100%);
            // span {
            //     color: $fontColor;
            // }
          }
          .chance {
            // background-color: #29294356;
            // // padding-top: 5px;
            // padding:4px;
            // border-radius: $radius;
            // position: absolute;
            // top: 5px;
            // right: 5px;
            // line-height: 1em;
            // opacity: 0;
            // font-family: $boldFont;
            // font-size: $fontSizeFour;
            // color: $secondaryFontColor;

            background-color: rgba(0, 0, 0, 0.15);
            padding: 6px 10px;
            border-radius: 15px;
            position: absolute;
            top: 10px;
            right: 10px;
            line-height: 1em;
            opacity: 0;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.9);
          }

          // @keyframes float {
          //   0%, 100% {
          //     transform: translate3d(0, 0, 0) rotateX(0) rotateY(0) rotateZ(0);
          //   }
          //   25% {
          //     transform: translate3d(5px, -5px, 5px) rotateX(5deg) rotateY(-5deg) rotateZ(5deg);
          //   }
          //   50% {
          //     transform: translate3d(-5px, 0, 0) rotateX(-5deg) rotateY(5deg) rotateZ(-5deg);
          //   }
          //   75% {
          //     transform: translate3d(5px, 5px, -5px) rotateX(-5deg) rotateY(-5deg) rotateZ(10deg);
          //   }
          // }

          .item-image-container {
            perspective: 200px;
            .item-image-item {
              transform-style: preserve-3d;
            }
          }
          .details {
            overflow: hidden;
            // display: none;
            position: absolute;
            bottom: 10px;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            top: 0;
            bottom: 0;
            padding-bottom: 13px;
            text-align: center;
            // padding-bottom: 10px;
            // font-size: 14px;

            border-radius: $radius;
            // background-color: #00000066;

            .mulit-value-container {
              position: absolute;
              top: 20px;
              left: 0;
              right: 0;
              overflow: hidden;

              &.positive .multi-value {
                color: $green;
              }
              &.negative .multi-value {
                color: #bcbcbc;
              }

              .multi-value {
                display: inline-block;
                transform: translateY(100%);
                font-weight: 700;
              }
            }

            .item-name-container {
              overflow: hidden;
            }
            // .item-name {
            //   //  color: $fontColorTwo;
            //   display: block;

            //   // position: absolute;
            //   color: rgba(255, 255, 255, 0.65);
            //   bottom: 0;
            //   left: 0;
            //   right: 0;
            //   transform: translateY(0%);
            // }
            .value-container {
              overflow: hidden;
              margin-bottom: 7px;
            }

            .value {
              font-size: 18px;

              display: flex;
              align-items: center;
              justify-content: center;
              gap: 2px;

              transform: translateY(100%);
              span {
                font-weight: 600;
                // color: $gold;
                font-family: $boldFont;
              }

              .icon {
                width: 20px;
              }
            }

            .spinning-background {
              position: absolute;
              top: -30px;
              left: -30px;
              right: -30px;
              bottom: -30px;

              opacity: 0;
              z-index: -1;
              pointer-events: none;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .case-game-options {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $padding;
    background-color: $primaryBackgroundColor;

    .case-cost {
      display: flex;
      // align-items: center;
      flex-direction: column;

      p {
        font-size: $fontSizeFour;
        padding-bottom: 5px;
      }
      .fiat-value {
        display: flex;
        align-items: center;
        margin-left: 20px;
      }
      .crypto-value {
        gap: 5px;
        font-size: $fontSizeThree;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .center-content {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      a {
        span {
          width: 200px;
        }
      }

      @media (max-width: $breakPointTwo) {
        flex-direction: column;
        gap: 20px;
      }

      .name-price {
        width: 200px;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          // padding-right: 10px;
          display: flex;
          align-items: center;
          font-size: 13px;
          .center-icon {
            display: inline;
            padding-top: 2px;
            padding-left: 4px;
            margin-left: 2px;
            padding-right: 4px;
            svg {
              transform: translateY(0px);
            }
          }
        }
      }
      .quick-toggle {
        margin-left: 20px;
        display: flex;
        align-items: center;

        p {
          font-family: $boldFont;
          font-size: $fontSizeFour;
          margin-left: 10px;
          width: 80px;
          line-height: 1;
        }
      }
    }
  }
  .case-content-heading {
    text-align: center;
    font-size: 18px;
    padding-top: $padding;
    padding-bottom: $padding;
  }
}
.case-detail-display {
  width: 100%;
  padding-right: 2px;
  padding-left: 2px;
  // display: flex;
  // flex-wrap: wrap;
  // border: 1px solid #fff;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  // padding-top: $padding;
  // padding-bottom: $padding;
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
  min-height: 50px;
  max-height: 375px;
  // border: 1px solid #fff;
  @include scrollbars(2px, $scrollBarColor, rgba(0, 0, 0, 0));
  border-radius: 6px;
  overflow-y: auto;

  .vertical-items {
    display: flex;
    flex-direction: column;

    gap: 20px;

    width: 100%;

    .card-items-container {
      display: flex;
      flex-direction: column;
      // gap: 5px;

      .bottom-fade {
        position: absolute;
        // border: 1px solid #fff;
        left: 0;
        right: 0;
        height: 80px;
        bottom: 0px;
        background: linear-gradient(
          to top,
          $primaryBackgroundColor,
          rgba(38, 46, 58, 0)
        );
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      &.add-bottom-fade {
        .bottom-fade {
          opacity: 1;
        }
      }
    }
  }

  &.mobile-one {
    .vertical-items {
      .card-items-container {
        .card-item-display {
          // border: 1px solid red;
          // flex-direction: column;
          // padding-bottom: 10px;
          // padding-left: 5px;
          // padding-right: 5px;

          .left-side-details {
            // flex-direction: column;
            .details .left-details .item-name {
              // border: 1px solid pink;
              max-width: 100px;
            }
          }
        }
      }
    }
  }

  &.mobile-two {
    .vertical-items {
      .card-items-container {
        .card-item-display {
          // border: 1px solid pink;
          // flex-direction: column;
          // padding-bottom: 10px;
          // padding-left: 5px;
          // padding-right: 5px;

          .left-side-details {
            flex-direction: column;
            align-items: flex-start;
            .item-image {
              margin-right: 0;
            }
            .details .left-details .item-name {
              // border: 1px solid #fff;
              max-width: 100px;
            }
          }
        }
      }
    }
  }

  &.three-player-display {
    .vertical-items {
      .card-items-container {
        // border: 1px solid #fff;

        .card-item-display {
          // border: 1px solid pink;
          // flex-direction: column;
          // padding-bottom: 10px;
          // padding-left: 5px;
          // padding-right: 5px;

          .left-side-details {
            flex-direction: column;
            align-items: flex-start;
            .item-image {
              margin-right: 0;
            }
            .details .left-details .item-name {
              // border: 1px solid #fff;
              max-width: 100px;
            }
          }
        }
      }
    }
  }

  // @media (max-width: $breakPointOne) {
  //   .vertical-items {
  //     .card-items-container {

  //       .card-item-display {
  //         // border: 1px solid pink;
  //         // flex-direction: column;
  //         // padding-bottom: 10px;
  //         // padding-left: 5px;
  //         // padding-right: 5px;

  //         .left-side-details {
  //           flex-direction: column;
  //           .details .left-details .item-name {
  //             // border: 1px solid #fff;
  //             max-width: 100px;

  //           }
  //         }

  //       }
  //     }
  //   }
  // }

  // @media (max-width: $breakPointOne) {
  //   .card-item-display {
  //     //   width: calc((100% / 2));
  //     //   flex: 1 1 calc((100% / 2) - 10px);
  //   }
  // }
}

.card-item-display {
  // width: calc(25% - 0.375rem);
  // width: 200px;
  // height: 220px;
  height: 0px;
  // padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background: $cardColor;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  // flex-direction: column;
  // height: 180px;
  // padding: $padding;
  position: relative;
  background-color: $primaryBackgroundColor;
  border-radius: $radius;
  // border: 1px solid rgba(255, 255, 255, 0.1);
  transition:
    opacity 0.3s ease 0.2s,
    transform 0.3s ease 0.2s,
    height 0.2s ease,
    margin 0.2s ease;

  opacity: 0;
  overflow: hidden;

  &.visible {
    height: 120px;
    opacity: 1;
    margin-bottom: 5px;
  }
  .card-item-multi-display {
    // position: absolute;
    // left: 20px;
    // top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
    // margin-bottom: 5px;

    .base-new-amount {
      display: flex;
      flex-direction: column;
      gap: 7px;
      align-items: flex-end;

      &.negative-multi {
        .multi-amount svg path {
          fill: #e8e5ff75;
        }

        .multi-price .new-value {
          span {
            color: #fff;
          }
        }
      }

      .multi-amount {
        font-size: 16px;
        font-weight: 600;
        display: flex;
        gap: 5px;
        align-items: center;

        span {
          font-size: 14px;
          font-weight: 400;
          color: #fff;
        }
      }

      .multi-price {
        display: flex;
        align-items: center;

        .base-amount {
          position: relative;
          .cross-out-line {
            position: absolute;
            border: 1px solid #818181;
            width: 100%;
            transform: translateY(7px);
          }
          span {
            color: #e8e5ff75 !important;
          }
          margin-right: 5px;
        }

        .new-value {
          span {
            color: #85f074;
          }
        }
      }
    }
  }

  .left-side-details {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    margin-right: 10px;
    flex: 1 1 50px;
  }
  .right-side-value {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1 1 50px;
  }

  &.uncommon {
    @include gradient(to top, rgba(48, 61, 75, 1), rgba(48, 61, 75, 0.3));
    background-origin: border-box;
  }

  // Purple items
  &.mythical {
    @include gradient(to top, rgba(59, 31, 112, 1), rgba(59, 31, 112, 0.3));
    background-origin: border-box;
  }

  // Rare items
  &.ancient {
    @include gradient(to top, rgba(89, 50, 52, 1), rgba(89, 50, 52, 0.3));
    background-origin: border-box;
  }

  // Legendary items
  &.immortal {
    @include gradient(to top, rgba(131, 96, 45, 1), rgba(131, 96, 45, 0.3));
    background-origin: border-box;
  }

  .item-image {
    margin-right: 10px;
    width: 50px;
    flex-shrink: 0;

    // margin-bottom: 20px;
  }

  .item-name {
    // padding-bottom: 20px;
    // color: $secondaryFontColor;
    color: rgba(255, 255, 255, 0.65);
    font-size: $fontSizeFour;
    font-family: $boldFont;
    max-width: 150px;
    text-align: left;
    white-space: nowrap; // Prevent the text from wrapping
    overflow: hidden; // Hide any text that overflows the element
    text-overflow: ellipsis;
    margin-top: 5px !important;
  }

  .details {
    // display: none;
    // position: absolute;
    // bottom: 15px;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    // gap: 20px;
    width: 100%;
    flex-direction: column;

    // padding-top: 20px;
    // font-size: 14px;
    // opacity: 0;
    .value {
      font-size: $fontSizeThree;
      color: $primaryFontColor;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 2px;
      flex-direction: column;

      span {
        color: $gold;
        font-family: $boldFont;
      }
    }
    .chance {
      // background-color: #2929433a;
      // // padding-top: 5px;
      // padding:4px;
      // border-radius: $radius;

      // line-height: 1em;
      // font-family: $boldFont;
      // font-size: $fontSizeFour;
      // color: $secondaryFontColor;

      // background-color: rgba(0, 0, 0, 0.15);
      padding: 6px 6px;
      border-radius: 15px;
      // position: absolute;

      line-height: 1em;

      font-size: 12px;
      // color: #cfcedf;
      color: rgba(255, 255, 255, 0.9);
      // position: absolute;
      // top: 10px;
      // right: 10px;
    }
    .left-details {
      width: 100%;
      margin-left: 7px;
      .icon {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: $boldFont;
        gap: 5px;
        svg {
          transform: translateY(-1px);
          width: 14px;
        }
      }
      p {
        margin: 0;
        // color: $gold;
      }
    }
  }

  @media (max-width: $breakPointTwo) {
    // border: 1px solid pink;
    // flex-direction: column;
    // padding-left: 0px;
    // padding-right: 0px;

    .left-side-details {
      flex-direction: column;
      margin: 0;
    }
  }

  @media (max-width: 650px) {
    // border: 1px solid yellow;
    flex-direction: column !important;
    flex-wrap: nowrap;

    &.visible {
      height: 180px;
    }
    padding-left: 0px;
    padding-right: 0px;

    .left-side-details {
      align-items: center !important;
    }
    .right-side-details {
      flex-direction: row;
    }
  }
}

$text-color: #333;
$text-shadow-1: 1px 1px 0 $text-color;
$text-shadow-2: 2px 2px 0 #043cbf;

.background-stand {
  display: none;
  position: absolute;
  bottom: -80px;
  left: 0;
  right: 0;
  width: 100%;
  margin-top: -30px;
  z-index: -1;
  svg {
    width: 100%;
  }
}
.all-cases-container {
  // padding: 100px;
  width: 60vw;
  margin-top: 30px;
  // width: 100%;
  display: flex;
  // padding-top: 25px;
  // align-items: center;
  flex-direction: column;
  // padding-bottom: 40px;

  @media (max-width: $breakPointTwo) {
    width: 100%;
  }

  .blue {
    font-size: 24px;
    color: $fontColor;
    // text-shadow: $text-shadow-1, $text-shadow-2, 0 0 20px #043CBF;
    margin-bottom: 40px;
  }
  .case-list-display-scroll-container {
    position: relative;

    // border: 1px solid #fff;
  }
  .case-list-display-scroll {
    overflow-y: auto;
    height: calc(var(--vh) * 45);
    padding-right: 5px;
    @include scrollbars(0.5em, $btnFill, transparent);
    position: relative;
    border-radius: $radius;
    // position: absolute;

    @media (max-width: $breakPointTwo) {
      height: calc(var(--vh) * 35);
    }
  }
  .case-row-bottom-fade {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(to top, $fifthBackground, rgba(38, 46, 58, 0));
  }

  .submit-modal-container {
    // border: 1px solid #fff;
    padding-top: 20px;
    padding-right: 10px;
    // padding-bottom: 10px;
    display: flex;
    justify-content: space-between;

    // flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;

    .left-side {
      display: flex;
      align-items: center;
      gap: 20px;

      .total-value {
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        span {
          font-size: 13px;
          color: $primaryFontColor;
        }
      }
      .amount-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
      }
    }

    .continue {
      width: 300px;
    }

    @media (max-width: $breakPointTwo) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-right: 0;

      .left-side {
        width: 100%;

        .total-value {
          width: 50%;
        }
      }

      .continue {
        width: 100%;
      }
    }
  }

  .cases-chest-display {
    position: relative;
    padding-bottom: 40px;
    // border: 1px solid #fff;

    // @media (max-width: $breakPointOne) {
    //   height: 370px;
    // }
    // @media (max-width: $breakPointTwo) {
    //   height: 350px;
    // }
    // @media (max-width: $breakPointThree) {
    //   height: 250px;
    // }

    h2 {
      font-size: 18px;
      padding-bottom: 20px;
    }

    .nav-buttons {
      display: flex;
      gap: 10px;
      position: absolute;
      right: 0px;
      top: 0px;

      button {
        svg {
          path {
            fill: $btnFillTwo;
          }
        }
        &:hover {
          svg {
            path {
              fill: $gold;
            }
          }
        }
      }
    }
  }

  .case-row {
    // width: 2000%;
    display: grid;

    // Use auto-fit and minmax for responsive grid items
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    // grid-auto-rows: 280px;
    // grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 20px;
    // position: absolute;
    opacity: 0;
    pointer-events: none;
    justify-content: flex-start;
    // border: 1px solid pink;
    // transform: translateX(100%);
    width: 100%;
    // height: 100%;

    transition:
      transform 500ms $easeInOut3,
      opacity 200ms $easeInOut3;

    display: grid;
    pointer-events: auto;
    opacity: 1;

    transition:
      transform 500ms $easeInOut3 200ms,
      opacity 200ms $easeInOut3 200ms;
  }

  @media (max-width: $breakPointOne) {
    flex-wrap: wrap;
  }

  .search-area {
    display: flex;
    gap: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
    .filter-area {
      width: 50%;
      // align-items: flex-end;
      display: flex;
      gap: 20px;
    }
  }

  @media screen and (max-width: 769px) {
    .search-area {
      // flex-direction: column;
      justify-content: space-between;
      .filter-area {
        width: 50%;
        // align-items: flex-end;
        display: flex;
        flex-direction: column;
        gap: 0px;
      }
    }
  }

  @media (max-width: $breakPointTwo) {
    .case-row {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
    .search-area {
      flex-direction: column;
      .filter-area {
        width: 100%;
      }
    }
  }
  @media (max-width: $breakPointThree) {
    .case-row {
      grid-template-columns: 1fr;
    }
  }
}

.case-item-display {
  // width: 150px;

  // flex: 1 1 35%;
  position: relative;
  // height: 280px;

  // background-color: $primaryBackgroundColor;
  border: 1px solid transparent;
  background: $cardColor;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;

  background-color: transparent;
  // height: 180px;
  // padding: $padding;

  position: relative;
  // background-color: rgba(28, 33, 62, 0);
  padding-top: 20px;
  padding-bottom: 20px;
  transition:
    border 300ms ease-in-out,
    background-color 300ms ease-in-out;

  .item-image {
    width: 70%;
    height: 100%;
    position: relative;

    @media (max-width: $breakPointTwo) {
      width: 50%;
    }

    // padding: 10px 0px 10px 0px;
  }
  .case-name {
    font-size: 13px;
    font-family: $boldFont;
    margin-bottom: 5px;
    line-height: 1.4;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .case-value-container {
    border-radius: 16px;
    overflow: hidden;
    // width: 80px;
    flex-shrink: 0;
    // padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    gap: 7px;
    margin-bottom: $padding;
    // height: 50px;
    // flex-direction: column;
    align-items: center;
    position: relative;
    // justify-content: ;
    // transition: background-color 300ms $easeInOut3,
    //   box-shadow 300ms $easeInOut3, border 300ms $easeInOut3;
    // background-color: #171c33;
    // border: 2px solid #4d5696;
    // background: #3d457c;

    .gradient-item {
      // background-color: pink;
      transition: opacity 500ms ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      opacity: 0;
      @include gradient(80deg, $darkPurple, $pink);
    }

    .inner-container {
      display: flex;
      gap: 7px;
      align-items: center;
      // padding-top: 2px;
    }

    span {
      color: #fff;
      font-size: 13px;
    }
  }

  .item-add-remove-btns {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    height: 50px;

    .amount-change {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      position: absolute;
      // border: 1px solid #fff;
      width: 100%;

      .amount-of-cases {
        width: 20px;
      }

      opacity: 0;
      pointer-events: none;
      transform: translateY(150%);

      transition:
        opacity 200ms ease-in-out,
        transform 200ms ease-in-out;
    }

    .add-case-item {
      transition:
        opacity 200ms ease-in-out,
        transform 200ms ease-in-out;
      position: absolute;
      left: 20px;
      right: 20px;
    }
  }
}
.case-select {
  display: inline-block;
  border-radius: $radius;
  overflow: hidden;
  // width: 400px;
  // width: calc((100% / 5) - 17px);
  flex: 0 0 auto;
  border: none !important;
  position: relative;
  // width: 180px;
  // height: 280px;
  // max-width: 240px;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at bottom,
    rgba(38, 44, 82, 1),
    rgba(38, 44, 82, 0.1)
  );

  &.selected {
    background:
      radial-gradient(
        58.05% 120.63% at 50.23% 100%,
        rgba(0, 119, 219, 0.6) 0%,
        rgba(0, 119, 219, 0.1) 100%
      ),
      radial-gradient(
        76.33% 100% at 50% 100%,
        #262c52 0%,
        rgba(38, 44, 82, 0.1) 100%
      ),
      #12172f;
    border: 1px solid #42a9ff !important;
    box-shadow: 0px 0px 20px 0px rgba(66, 169, 255, 0.4) inset;
  }

  &.no-selected {
  }

  .change-position-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;

    .change-position-icon-container {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 20px;
      transform: translate(-50%, -50%);
      border-radius: 9999px;
      border: 1px solid #7c83b1;
      background:
        radial-gradient(
          58.05% 120.63% at 50.23% 100%,
          rgba(0, 119, 219, 0.3) 0%,
          rgba(0, 119, 219, 0.05) 100%
        ),
        linear-gradient(
          180deg,
          rgba(124, 131, 177, 0.5) 0%,
          rgba(94, 103, 158, 0.5) 100%
        );
    }
  }

  .case-item-select-container {
    // border: 1px solid #fff;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 30%;

    &:hover {
      cursor: move;
    }
  }

  // &:hover {

  //   cursor: move;
  // }
  // &.dragging {
  //   cursor: grabbing !important;
  // }

  // @media (max-width: $breakPointOne) {

  //     width: calc((100% / 2) - 17px);
  // }

  .case-item-alert {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 10;
    width: 20px;
    height: 20px;
    svg {
      width: 100%;
    }
    svg path {
      fill: rgba(255, 255, 255, 0.2);
    }
  }

  .active-background {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: radial-gradient(
      circle at bottom,
      rgba(0, 119, 219, 1),
      rgba(38, 44, 82, 0.1)
    );
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  &.active-item {
    .active-background {
      opacity: 1;
    }

    .case-item-display {
      .item-add-remove-btns {
        .amount-change {
          pointer-events: auto;
          opacity: 1;
          transform: translateY(0%);
        }
        .add-case-item {
          pointer-events: none;
          opacity: 0;
          transform: translateY(-100%);
        }
      }
    }
  }
}
.tippy-content {
  background-color: rgba(20, 25, 40, 0.9);
  padding: 8px 14px;
  border-radius: 6px;
  min-width: 100px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.9);
}

.no-results-message:not(:last-of-type) {
  display: none;
}
